import { render, staticRenderFns } from "./Register.vue?vue&type=template&id=3d3b050e&scoped=true&"
import script from "./Register.vue?vue&type=script&lang=js&"
export * from "./Register.vue?vue&type=script&lang=js&"
import style0 from "./Register.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./Register.vue?vue&type=style&index=1&id=3d3b050e&scoped=true&lang=css&"
import style2 from "./Register.vue?vue&type=style&index=2&lang=css&"
import style3 from "./Register.vue?vue&type=style&index=3&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d3b050e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VCol,VContainer,VDialog,VIcon,VRadio,VRadioGroup,VRow,VSpacer,VTabItem,VTextField})
